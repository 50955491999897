module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":768,"linkImagesToOriginal":false,"showCaptions":["title","alt"],"withWebp":true,"markdownCaptions":true,"tracedSVG":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"resta-dev","short_name":"resta-dev","start_url":"/","background_color":"#f3f9fb","theme_color":"#f3f9fb","display":"minimal-ui","icon":"src/assets/images/web-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"52e1f1e4d879474917c3d925a00c5930"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"blue","showSpinner":false},
    }]
